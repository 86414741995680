<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="https://eefim.com">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />
                          </a>
                        </div>

                        <b-alert v-if="this.error" show variant="danger">
                          {{$t('Authentication.ConfirmEmail.ErrorTokenEmpty')}}
                        </b-alert>

                        <b-spinner v-if="!this.error" style="width: 3rem; height: 3rem;" class="mt-3" variant="primary" role="status"></b-spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userService } from '@/helpers/fakebackend/user.service';

export default {
  name: "confirm-email",
  props: ["token"],
  data() {
    return {
      error: false
    };
  },
  methods: {
    ...userService
  },
  mounted() {
    if (!this.token) {
      this.error = true
      return
    }

    userService.activateUser(this.token).then(
        () => {
          this.$router.push('/')
          this.$bvToast.toast(this.$t('General.Toast.Title.Success'), {
            title: this.$t('Constructor.Toasts.DoubleSave.Title'),
            variant: 'info',
            solid: true
          })
        },
        () => {
          this.error = true
        }
    )
  }
}
</script>

<style scoped>

</style>